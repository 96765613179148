@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "headline";
  src: url("./style/fonts/Loving Heart Regular.otf") format("opentype");
  /* src: url('./style/fonts/Mique.ttf') format('truetype'); */
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "subheadline";
  src: url("./style/fonts/MyriadProBold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "plaintext";
  src: url("./style/fonts/MyriadPro-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
}


